import React, { useState } from 'react';
import './Login-Register.css';

const LoginRegister = () => {

    const [from, setfrom] = useState(false);

    return (
        <>

            <div className={"loginRegister-wrapper " + (from ? 'loginRegister-wrapperRed' : '')}>

                <div className="square square1"></div>
                <div className="square square2"></div>
                <div className="square square3"></div>
                <div className="square square4"></div>
                <div className="square square5"></div>

                <div className="loginRegister-container">
                    <div className="loginRegister-Box">
                        <div className="box signIn">
                            <h2>Already Have an Account ?</h2>
                            <button className='signInbtn' onClick={() => setfrom(!from)}>Sign in</button>
                        </div>

                        <div className="box signUp">
                            <h2>Don't Have an Account ?</h2>
                            <button className='signUnbtn' onClick={() => setfrom(!from)}>Sign Up</button>
                        </div>
                    </div>
                    <div className={"fromBx " + (from ? 'fromActive' : '')} >
                        <div className="form signinFrom">
                            <h3>Sign In</h3>
                            <form action="">
                                <div className="inputbox">
                                    <input type="text" required="required" />
                                    <span>Name</span>
                                    <i></i>
                                </div>
                                <div className="inputbox">
                                    <input type="Password" required="required" />
                                    <span>Password</span>
                                    <i></i>
                                </div>
                                <button className='animatebtn loginbtn'><span>Login</span></button>
                                <a href='/'> Forget Password</a>
                            </form>
                        </div>

                        <div className="form signUpFrom">
                            <h3>Sign Up</h3>
                            <form action="">
                                <div className="inputbox">
                                    <input type="text" required="required" />
                                    <span>User Name</span>
                                    <i></i>
                                </div>
                                <div className="inputbox">
                                    <input type="email" required="required" />
                                    <span>Email Address</span>
                                    <i></i>
                                </div>
                                <div className="inputbox">
                                    <input type="Password" required="required" />
                                    <span>Password</span>
                                    <i></i>
                                </div>
                                <div className="inputbox">
                                    <input type="text" required="required" />
                                    <span>Confrim Password</span>
                                    <i></i>
                                </div>
                                <button className='animatebtn Registerbtn'><span>Register</span></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default LoginRegister